import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Layout from "../components/layout";
import Seo from "../components/seo";

export default function OverMij() {
  const data = useStaticQuery(graphql`
    query {
      contentfulOverMij(contentful_id: { eq: "1zs8e1pTLw4X0tv4zNkLNz" }) {
        id
        body {
          json
        }
        title
        intro {
          json
        }
        bodyEnd {
          json
        }
        overmijImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
      }
    }
  `);

  const { body, title, intro, bodyEnd, overmijImage } = data.contentfulOverMij;

  return (
    <Layout>
      <Seo
        title="Over Mij"
        description="Deze pagina beschrijft wie ik ben"
      />
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        justify="space-between"
        style={{ marginBottom: "4rem" }}
      >
        <Grid item xs={12}>
          <div className="textStyleTitleOverMij">
            {title}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={0} style={{ width: "100%", height: "50vh" }} >
            <Img
              fluid={overmijImage.fluid}
              fadeIn
              alt={overmijImage.description}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
            ></Img>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(intro.json)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(body.json)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography color="textPrimary" component="div" align="center">
            {documentToReactComponents(bodyEnd.json)}
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}
